@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.3/font/bootstrap-icons.css");

:root{
  --box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  list-style: none;
}

.App{
  min-height: 100vh;
}

.container{
  max-width: 1200px;
  margin: auto;
  padding: 10px 10px;
}

.light{
  background-color: #FFFFFF;
  transition: all 500ms;  
}

.medium{
  background-color: #EFEFEF;
  transition: all 500ms;
}

.dark{
  background-color: #15202B;
  transition: all 500ms;
}

.gradientOne{
  background: linear-gradient(90deg, hsla(191, 75%, 60%, 1) 0%, hsla(248, 87%, 36%, 1) 100%);
}
.gradientTwo{
  background: linear-gradient(235deg, #FFFFFF 0%, #000F25 100%), linear-gradient(180deg, #6100FF 0%, #000000 100%), linear-gradient(235deg, #FFA3AC 0%, #FFA3AC 40%, #00043C calc(40% + 1px), #00043C 60%, #005D6C calc(60% + 1px), #005D6C 70%, #00C9B1 calc(70% + 1px), #00C9B1 100%), linear-gradient(125deg, #FFA3AC 0%, #FFA3AC 40%, #00043C calc(40% + 1px), #00043C 60%, #005D6C calc(60% + 1px), #005D6C 70%, #00C9B1 calc(70% + 1px), #00C9B1 100%);
  background-blend-mode: soft-light, screen, darken, normal;
}

.gradientThree{
  background: linear-gradient(125deg, #FFFFFF 0%, #000000 100%), linear-gradient(200deg, #FFD9E8 0%, #FFD9E8 50%, #DE95BA calc(50% + 1px), #DE95BA 60%, #7F4A88 calc(60% + 1px), #7F4A88 75%, #4A266A calc(75% + 1px), #4A266A 100%), linear-gradient(113deg, #FFD9E8 0%, #FFD9E8 40%, #DE95BA calc(40% + 1px), #DE95BA 50%, #7F4A88 calc(50% + 1px), #7F4A88 70%, #4A266A calc(70% + 1px), #4A266A 100%);
  background-blend-mode: overlay, overlay, normal;
}

header{
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin-bottom: 70px;
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
}

header .logo{
  display: flex;
  align-items: center;
}

header .logo img{
  max-width: 35px;
  cursor: pointer;
}

header .logo span{
  font-size: 22px;
  margin-left: 7px;
  cursor: pointer;
  color: #525252;
}

header .themeSelector span{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  border: 1px solid #dfdfdf;
}

header .themeSelector .activeTheme{
  height: 30px;
  width: 30px;
}

.addTask {
  padding: 20px 15px;
  border-radius: 5px;
  background-color: #FFFFFF;
  max-width: 500px;
  margin: 20px auto;
  box-shadow: var(--box-shadow);
}

.addTask form{
  display: flex;
  gap: 7px;
}

.addTask form input{
  flex-grow: 1;
  padding: 5px 5px;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  font-size: 18px;
}

.addTask form input:focus{
  outline: 0.5px solid #eaeaea;
}

.addTask form button{
  background-color: #03ba5c;
  color: #FFFFFF;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

.addTask form button:hover{
  background-color: #03b056;
}

.showTask{
  padding: 20px 15px;
  border-radius: 5px;
  background-color: #FFFFFF;
  margin: 20px auto;
  box-shadow: var(--box-shadow);
}

.showTask p.head{
  padding: 10px 0px 20px 0px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  align-items: center;
}

.showTask p.head span:first-child{
  flex-grow: 1;
}

.showTask .title{
  font-size: 18px;
  font-weight: 600;
  user-select: none;
}

.showTask .count{
  background-color: #dfdfdf;
  color: #525252;
  padding: 5px 10px;
  border-radius: 50%;
  margin-left: 10px;
  user-select: none;
}

.showTask .clearAll{
  background-color: #0f6af3;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.showTask .clearAll:hover{
  background-color: #1061db;
}

.showTask ul{
  margin: 30px 5px 20px 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 15px;
}

.showTask li{
  padding: 15px 10px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  gap: 5px;
  width: 350px;
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  border-left: 5px solid #1365dfaf;
}

.showTask li:hover{
  background-color: #f8f8f8;
}

.showTask li p{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.showTask li .name{
  font-size: 18px;
  padding-bottom: 5px;
}

.showTask li .time{
  font-size: 12px;
  color: #737373;
}

.showTask i.bi-pencil-square{
  font-size: 18px;
  color: #1363DF;
  cursor: pointer;
}

.showTask i.bi-trash{
  font-size: 18px;
  color: #D82148;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .showTask li{
    width: 100%;
  }
}